<template>
  <ul v-if="listItems.length" :style="styles">
    <li
      v-for="(listItem, index) in listItems"
      :key="index"
      :class="`flex align-middle mb-${props.spaceBetweenListItems}`"
    >
      <div
        class="border-dotted shrink-0"
        :class="[
          `mr-${props.spaceBetweenIconAndText}`,
          bulletBig || listType != EListType.Bullet
            ? 'rounded-[16px] border-2 w-[26px] h-[26px] mt-[2px]'
            : '!border-none mt-[7px]',
        ]"
        :style="`border-color: ${iconBGColor}`"
      >
        <div
          class="rounded-xl m-auto mt-[2px] text-white flex place-items-center justify-center"
          :class="
            bulletBig || listType != EListType.Bullet
              ? 'w-[18px] h-[18px]'
              : 'w-[9px] h-[9px]'
          "
          :style="`background-color: ${iconBGColor}`"
        >
          <div
            v-if="listType === EListType.Number"
            class="text-[12px]"
            v-text="props.startIndex + index"
          />
          <FaIcon
            v-else-if="iconClass !== null"
            classes="text-white text-[12px]"
            :icon-class="iconClass"
          />
        </div>
      </div>
      <span
        :class="bulletBig || listType != EListType.Bullet ? 'mt-[2px]' : 'mt-0'"
        ><Wysiwyg :no-container-padding="true" :content="listItem.text"
      /></span>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { EListType, type IListItem } from '@/@types/bullet-list';
import FaIcon from '@/components/fa-icon.vue';
import type { PropType } from 'vue';
import Wysiwyg from '~/components/components/cms/wysiwyg/wysiwyg.vue';

/** Props */
const props = defineProps({
  listType: {
    type: Number as PropType<EListType>,
    default: EListType.Bullet,
  },
  listItems: {
    type: Array as PropType<IListItem[]>,
    default: null,
  },
  startIndex: {
    type: Number,
    default: 1,
  },
  spaceBetweenIconAndText: {
    type: String,
    required: false,
    default: 'xs',
  },
  spaceBetweenListItems: {
    type: String,
    required: false,
    default: 'xs',
  },
  styles: {
    type: String,
    required: false,
    default: '',
  },
  bulletBig: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const iconBGColor = setIconBGColor();
const iconClass = setIconClass();

function setIconClass() {
  switch (props.listType) {
    case EListType.CheckMarksDarkGreen:
    case EListType.CheckMarksLightGreen:
    case EListType.CheckMarksBrown:
    case EListType.CheckMarksPurple:
    case EListType.CheckMarksPrimary:
      return 'fas fa-check';
    case EListType.CheckMarksGrey:
      return 'fas fa-xmark';
    case EListType.CheckMarksRed:
      return 'fas fa-minus';
    default:
      return null;
  }
}

function setIconBGColor() {
  switch (props.listType) {
    case EListType.CheckMarksPrimary:
      return 'var(--thm-primary-base)';
    case EListType.CheckMarksGrey:
    case EListType.CheckCirclesGrey:
      return 'var(--thm-grey-light)';
    case EListType.CheckMarksLightGreen:
    case EListType.CheckCirclesLightGreen:
      return 'var(--thm-status-success-base)';
    case EListType.CheckMarksRed:
    case EListType.CheckCirclesRed:
      return 'var(--thm-status-danger-base)';
    case EListType.CheckMarksPurple:
    case EListType.CheckCirclesPurple:
      return '#AA5A9F';
    default:
      return 'var(--thm-primary-base)';
  }
}
</script>

<style scoped lang="postcss">
:deep(ol) {
  @apply list-disc ml-sm pb-0 mb-0 pt-2xs;
}

:deep(ul) {
  @apply list-disc ml-sm pb-0 mb-0 pt-2xs;
}
:deep(ol, ul > li) {
  @apply pb-2xs;
}
</style>
