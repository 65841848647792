export interface IListItem {
  text: string;
}

export enum EListType {
  Bullet = 0,
  Number = 1,
  CheckMarksDarkGreen = 2,
  CheckCirclesDarkGreen = 3,
  CheckMarksGrey = 4,
  CheckCirclesGrey = 5,
  CheckMarksLightGreen = 6,
  CheckCirclesLightGreen = 7,
  CheckMarksRed = 8,
  CheckCirclesRed = 9,
  CheckMarksBrown = 10,
  CheckMarksPurple = 11,
  CheckCirclesPurple = 12,
  CheckMarksPrimary = 13,
}

export enum EListFlow {
  TopToBottom = 'topToBottom',
  LeftToRight = 'leftToRight',
}
